import { useContext } from 'react'
import cx from 'classnames'

import {
  SanityBlogPostWithoutBody,
  SanityBlogPostOptions,
} from '@data/sanity/queries/types/blog'
import { getFormattedDate } from '@lib/helpers'
import { LanguageContext } from '@lib/language'
import { getPageUrl, PageType } from '@lib/routes'

import Photo from '@components/photo'
import SimpleLink from '@components/simple-link'

import { backgroundColorClasses } from '@modules/grid'
import { SanityColor } from '@data/sanity/queries/types/modules'

interface BlogPostCardProps {
  post: SanityBlogPostWithoutBody
  options?: SanityBlogPostOptions
}

const BlogPostCard = ({ post, options }: BlogPostCardProps) => {
  const { locale } = useContext(LanguageContext)

  const color = options?.color ?? post.color ?? SanityColor.WHITE

  return (
    <SimpleLink
      href={getPageUrl(PageType.BLOG_POST, post.slug)}
      className={cx(
        'flex flex-col rounded-2xl overflow-hidden cursor-pointer',
        backgroundColorClasses[color]
      )}
    >
      {post.coverImage && options?.showCoverImage && (
        <Photo
          image={{
            ...post.coverImage,
            customRatio: 1.7777777778,
          }}
        />
      )}

      <div className="p-5 h-full flex flex-col justify-between">
        <div className="flex flex-col gap-y-3">
          <h3>{post.title}</h3>
          {options?.showExcerpt && <p className="text-base">{post.excerpt}</p>}
        </div>

        {(options?.showCategories ||
          options?.showAuthorPhoto ||
          options?.showAuthorName ||
          options?.showDate) && (
          <div
            className={cx(
              'flex flex-col gap-y-2 pt-4 mt-4 border-t border-opacity-25',
              color === SanityColor.WHITE ||
                color === SanityColor.GRAY ||
                color === SanityColor.GREEN_LIGHT
                ? 'border-black'
                : 'border-white'
            )}
          >
            {options.showCategories && (
              <div className="flex flex-wrap gap-3">
                {post.categories?.map((category) => (
                  <SimpleLink
                    key={`${post.slug}-category-${category.slug}`}
                    href={getPageUrl(PageType.BLOG_CATEGORY, category.slug)}
                    className="text-base"
                  >
                    {category.name}
                  </SimpleLink>
                ))}
              </div>
            )}

            {(options.showAuthorPhoto ||
              options.showAuthorName ||
              options.showDate) && (
              <div
                className={cx('flex items-center', {
                  'mt-1': options.showAuthorPhoto,
                })}
              >
                {options?.showAuthorPhoto && post.author?.photo && (
                  <Photo
                    image={post.author?.photo}
                    objectFit="cover"
                    forceWidth={40}
                    forceHeight={40}
                    className="rounded-full overflow-hidden shrink-0 w-[40px] h-[40px] mr-2"
                  />
                )}
                {(options?.showAuthorName || options?.showDate) && (
                  <p className="text-sm">
                    {options.showAuthorName && (
                      <>
                        {/* TODO: Add hardcoded string to Sanity */}
                        by{' '}
                        <SimpleLink
                          href={getPageUrl(
                            PageType.BLOG_AUTHOR,
                            post.author?.slug
                          )}
                        >
                          {post.author?.name}
                        </SimpleLink>
                      </>
                    )}

                    {options.showDate
                      ? `${
                          options.showAuthorName ? ', ' : ''
                        }${getFormattedDate(post.date, locale)}`
                      : ''}
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </SimpleLink>
  )
}

export default BlogPostCard
