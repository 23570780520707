import { useContext } from 'react'
import BlockContent from '@sanity/block-content-to-react'

import {
  OrderFinancialStatus,
  OrderFulfillmentStatus,
} from '@data/shopify/types/globalTypes'
import {
  HasAccountStrings,
  SanityAccountStrings,
} from '@data/sanity/queries/types/site'
import { getPrice } from '@lib/helpers'
import { LanguageContext } from '@lib/language'
import { serializers } from '@lib/serializers'
import { ShopContext } from '@lib/shop'
import { StringsContext } from '@lib/strings'
import { useUserOrderList } from '@lib/user'

import Button, { ButtonSize, ButtonVariant } from '@components/buttons/button'

interface AccountOrderListProps extends HasAccountStrings {
  className?: string
}

type PaymentStatusValues<T> = {
  [P in OrderFinancialStatus]: T
}

type FulfillmentStatusValues<T> = {
  [P in OrderFulfillmentStatus]: T
}

/**
 * Gets order payment status texts.
 */
const getPaymentStatuses = (
  accountStrings: SanityAccountStrings
): PaymentStatusValues<string> => ({
  [OrderFinancialStatus.AUTHORIZED]:
    accountStrings.accountOrderListPaymentStatusAuthorized,
  [OrderFinancialStatus.PAID]: accountStrings.accountOrderListPaymentStatusPaid,
  [OrderFinancialStatus.PARTIALLY_PAID]:
    accountStrings.accountOrderListPaymentStatusPartiallyPaid,
  [OrderFinancialStatus.PARTIALLY_REFUNDED]:
    accountStrings.accountOrderListPaymentStatusPartiallyRefunded,
  [OrderFinancialStatus.PENDING]:
    accountStrings.accountOrderListPaymentStatusPending,
  [OrderFinancialStatus.REFUNDED]:
    accountStrings.accountOrderListPaymentStatusRefunded,
  [OrderFinancialStatus.VOIDED]:
    accountStrings.accountOrderListPaymentStatusVoided,
})

/**
 * Gets order fulfillment status texts.
 */
const getFulfillmentStatuses = (
  accountStrings: SanityAccountStrings
): FulfillmentStatusValues<string> => ({
  [OrderFulfillmentStatus.FULFILLED]:
    accountStrings.accountOrderListFulfillmentStatusFulfilled,
  [OrderFulfillmentStatus.IN_PROGRESS]:
    accountStrings.accountOrderListFulfillmentStatusInProgress,
  [OrderFulfillmentStatus.ON_HOLD]:
    accountStrings.accountOrderListFulfillmentStatusOnHold,
  [OrderFulfillmentStatus.OPEN]:
    accountStrings.accountOrderListFulfillmentStatusOpen,
  [OrderFulfillmentStatus.PARTIALLY_FULFILLED]:
    accountStrings.accountOrderListFulfillmentStatusPartiallyFulfilled,
  [OrderFulfillmentStatus.PENDING_FULFILLMENT]:
    accountStrings.accountOrderListFulfillmentStatusPendingFulfillment,
  [OrderFulfillmentStatus.RESTOCKED]:
    accountStrings.accountOrderListFulfillmentStatusRestocked,
  [OrderFulfillmentStatus.SCHEDULED]:
    accountStrings.accountOrderListFulfillmentStatusScheduled,
  [OrderFulfillmentStatus.UNFULFILLED]:
    accountStrings.accountOrderListFulfillmentStatusUnfulfilled,
})

const AccountOrderList = ({
  accountStrings,
  className,
}: AccountOrderListProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)
  const { orders, hasMoreOrders, loadMoreOrders, isLoadingOrders } =
    useUserOrderList()

  const paymentStatuses = getPaymentStatuses(accountStrings)
  const fulfillmentStatuses = getFulfillmentStatuses(accountStrings)

  const dateFormat = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <div className={className}>
      <h4>{accountStrings.accountOrderListHeading}</h4>

      {orders.length === 0 && !isLoadingOrders && (
        <BlockContent
          renderContainerOnSingleChild
          className="rc mt-3"
          blocks={accountStrings.accountOrderListEmpty}
          serializers={serializers}
        />
      )}

      {orders.length > 0 && (
        <div className="mt-4">
          <div className="justify-evenly py-4 px-5 text-pageText text-sm hidden sm:flex">
            <div className="w-[20%] pr-3">
              {accountStrings.accountOrderListOrder}
            </div>
            <div className="w-[20%] pr-3">
              {accountStrings.accountOrderListDate}
            </div>
            <div className="w-[20%] pr-3">
              {accountStrings.accountOrderListPaymentStatus}
            </div>
            <div className="w-[20%] pr-3">
              {accountStrings.accountOrderListFulfillmentStatus}
            </div>
            <div className="w-[20%] text-right">
              {accountStrings.accountOrderListTotal}
            </div>
          </div>

          {orders.map((order) => (
            <a
              key={order.id}
              className="flex flex-col sm:flex-row cursor-pointer py-5 px-5 bg-box-bg border-t border-box-border border-opacity-25 text-sm"
              href={order.url}
              target="_blank"
              rel="noreferrer"
            >
              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountOrderListOrder}
                </div>

                <div className="text-right sm:text-left sm:pr-3">
                  {order.id}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountOrderListDate}
                </div>

                <div className="text-right sm:text-left sm:pr-3">
                  {dateFormat.format(new Date(order.date))}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountOrderListPaymentStatus}
                </div>

                <div className="text-right sm:text-left sm:pr-3">
                  {order.paymentStatus
                    ? paymentStatuses[order.paymentStatus]
                    : '-'}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountOrderListFulfillmentStatus}
                </div>

                <div className="text-right sm:text-left sm:pr-3">
                  {fulfillmentStatuses[order.fulfillmentStatus]}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountOrderListTotal}
                </div>

                <div className="text-right">
                  {currency}
                  {getPrice(order.total * 100, taxRate)}
                </div>
              </div>
            </a>
          ))}

          {hasMoreOrders && (
            <div className="mt-4 text-center">
              <Button
                variant={ButtonVariant.OUTLINED}
                size={ButtonSize.SMALL}
                disabled={isLoadingOrders}
                className="min-w-[136px]"
                onClick={loadMoreOrders}
              >
                {isLoadingOrders
                  ? strings.buttonLoading
                  : strings.buttonLoadMore}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AccountOrderList
