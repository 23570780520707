import {
  SanityContentCarousel,
  SanityContentCarouselItem,
  SanityContentCarouselItemType,
} from '@data/sanity/queries/types/blocks'
import { SanityVideoType } from '@data/sanity/queries/types/video'

import Carousel from '@components/carousel'
import ProductCard from '@blocks/shop/product-card'
import BlogPostCard from '@blocks/blog/blog-post-card'
import Freeform from '@blocks/freeform'
import Photo from '@components/photo'
import VimeoVideo from '@components/video/vimeo-video'
import MuxVideo from '@components/video/mux-video'

type ContentCarouselItemProps = { item: SanityContentCarouselItem }

const Item = ({ item }: ContentCarouselItemProps) => {
  switch (item._type) {
    case SanityContentCarouselItemType.FREEFORM:
      return (
        <Freeform
          content={item.freeform.content}
          textAlign={item.freeform.textAlign}
          maxWidth={item.freeform.maxWidth}
        />
      )

    case SanityContentCarouselItemType.PHOTO:
      return <Photo image={item.photo} />

    case SanityContentCarouselItemType.PRODUCT:
      return (
        <ProductCard
          product={item.product}
          hasVisuals={
            !!item.product.photos.main || !!item.product.photos.listing
          }
          showGallery={
            item.product.useGallery === 'true' && !!item.product.photos.main
          }
          showThumbnails={
            item.product.useGallery === 'false' && !!item.product.photos.listing
          }
          showOption={!!item.product.surfaceOption}
          showPrice
        />
      )

    case SanityContentCarouselItemType.BLOG_POST:
      return <BlogPostCard post={item.blogPost} options={{ showDate: true }} />

    case SanityContentCarouselItemType.VIDEO:
      const { video } = item

      return video.muxVideo && video.type === SanityVideoType.MUX ? (
        <MuxVideo
          video={video.muxVideo}
          showControls={video.settings?.controls}
          autoplay={video.settings?.autoplay}
          loop={video.settings?.loop}
          muted={video.settings?.muted}
          borderRadius={video.borderRadius}
          aspectRatio={video.aspectRatio}
        />
      ) : video.vimeoVideo && video.type === SanityVideoType.VIMEO ? (
        <VimeoVideo
          video={video.vimeoVideo}
          borderRadius={video.borderRadius}
        />
      ) : null
  }
}

type ContentCarouselProps = SanityContentCarousel

const ContentCarousel = ({ items }: ContentCarouselProps) => {
  const options = {
    selector: '.content-slide',
  }

  return (
    <Carousel options={options}>
      {items.map((item, index) => {
        return (
          <div className="content-slide" key={index}>
            <Item item={item} />
          </div>
        )
      })}
    </Carousel>
  )
}

export default ContentCarousel
