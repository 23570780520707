import { SanityVideo, SanityVideoType } from '@data/sanity/queries/types/video'

import MuxVideo from '@components/video/mux-video'
import VimeoVideo from '@components/video/vimeo-video'

const VideoModule = ({
  type,
  vimeoVideo,
  muxVideo,
  settings,
  aspectRatio,
  borderRadius,
}: SanityVideo) => (
  <>
    {type === SanityVideoType.VIMEO && vimeoVideo && (
      <VimeoVideo video={vimeoVideo} borderRadius={borderRadius} />
    )}

    {type === SanityVideoType.MUX && muxVideo && (
      <MuxVideo
        video={muxVideo}
        showControls={settings?.controls}
        autoplay={settings?.autoplay}
        loop={settings?.loop}
        muted={settings?.muted}
        borderRadius={borderRadius}
        aspectRatio={aspectRatio}
      />
    )}
  </>
)

export default VideoModule
