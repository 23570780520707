import { Fragment, useContext } from 'react'
import cx from 'classnames'

import { HasAccountStrings } from '@data/sanity/queries/types/site'
import { getShopifySubscriptionManagementUrl } from '@lib/shopify/customer'
import { useUser } from '@lib/auth'
import { getPageUrl, PageType } from '@lib/routes'
import { ShopContext } from '@lib/shop'

import { ButtonIconSize, ButtonIconAlignment } from '@components/buttons/button'
import ButtonLink from '@components/buttons/button-link'

interface AccountDetailsProps extends HasAccountStrings {
  className?: string
}

const AccountDetails = ({ accountStrings, className }: AccountDetailsProps) => {
  const { shopifyPrimaryDomain } = useContext(ShopContext)
  const { user } = useUser()

  const addressCount = user?.addresses?.length ?? 0
  const defaultAddress =
    user?.addresses?.find((address) => address.isDefault) ??
    user?.addresses?.[0]
  const subscriptionManagementUrl = user?.subscriberHash
    ? getShopifySubscriptionManagementUrl(
        shopifyPrimaryDomain,
        user.subscriberHash
      )
    : null

  return (
    <div
      className={cx(className, 'bg-box-bg border border-box-border p-6 lg:p-8')}
    >
      <h4>{accountStrings.accountAccountDetailsHeading}</h4>

      {defaultAddress && (
        <div className="mt-5 text-sm leading-normal">
          {defaultAddress.formatted.map((addressLine, index) => (
            <Fragment key={index}>
              {addressLine}
              {defaultAddress.formatted[index + 1] && <br />}
            </Fragment>
          ))}
        </div>
      )}

      <ButtonLink
        href={getPageUrl(PageType.ACCOUNT_ADDRESS_PAGE)}
        className="w-full mt-5 text-sm font-medium"
        icon="ArrowRight"
        iconSize={ButtonIconSize.NORMAL}
        iconAlignment={ButtonIconAlignment.LEFT}
      >
        {accountStrings.accountViewAddresses} ({addressCount})
      </ButtonLink>

      {subscriptionManagementUrl && (
        <ButtonLink
          href={subscriptionManagementUrl}
          target="_blank"
          className="w-full mt-5 text-sm font-medium"
          icon="ArrowRight"
          iconAlignment={ButtonIconAlignment.LEFT}
        >
          {accountStrings.accountViewSubscriptions}
        </ButtonLink>
      )}
    </div>
  )
}

export default AccountDetails
