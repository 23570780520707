import { useCallback, useContext, useEffect, useState } from 'react'

import {
  OrderFinancialStatus,
  OrderFulfillmentStatus,
} from '@data/shopify/types/globalTypes'
import { getShopifyUserOrders } from './shopify/graphql/customer'
import {
  getCustomerHasMoreOrders,
  getCustomerOrderCursor,
  parseCustomerOrders,
} from './shopify/customer'
import { useUser } from './auth'
import { LanguageContext } from './language'
import { getUserDigitalProducts } from './product'
import { ShopContext } from './shop'

export interface AddressFormValues {
  firstName: string
  lastName: string
  company: string
  address1: string
  address2: string
  city: string
  country: string
  zip: string
  phone: string
  isDefault: boolean
}

export interface UserAddress {
  id: string
  globalId?: number
  isDefault: boolean
  formatted: string[]
  values: AddressFormValues
}

export interface UserOrder {
  id: string
  date: string
  paymentStatus: OrderFinancialStatus | null
  fulfillmentStatus: OrderFulfillmentStatus
  total: number
  url: string
}

export interface UserProduct {
  id: number
  title: string
  slug: string
}

export const USER_LIST_PAGE_LIMIT = 10

/**
 * User order list hook.
 */
export const useUserOrderList = () => {
  const { shopifyStorefrontClient } = useContext(ShopContext)
  const { user } = useUser()
  const [orders, setOrders] = useState<UserOrder[]>([])
  const [orderCursor, setOrderCursor] = useState<string | null>(null)
  const [hasMoreOrders, setHasMoreOrders] = useState(false)
  const [isLoadingOrders, setIsLoadingOrders] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)

  const clearOrders = () => {
    setOrders([])
    setOrderCursor(null)
    setHasMoreOrders(false)
    setIsInitialized(false)
  }

  // Read more orders
  const loadMoreOrders = useCallback(async () => {
    if (!user?.token) {
      return
    }

    if (!shopifyStorefrontClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setIsLoadingOrders(true)

    const shopifyUserOrders = await getShopifyUserOrders(
      shopifyStorefrontClient,
      user.token,
      orderCursor
    )
    setOrders((orders) => [
      ...(orders ?? []),
      ...parseCustomerOrders(shopifyUserOrders),
    ])
    setOrderCursor(getCustomerOrderCursor(shopifyUserOrders) ?? null)
    setHasMoreOrders(getCustomerHasMoreOrders(shopifyUserOrders) ?? false)

    setIsLoadingOrders(false)
  }, [orderCursor, shopifyStorefrontClient, user?.token])

  // Initialize order list
  useEffect(() => {
    if (!isInitialized && user?.token) {
      // Load first page
      loadMoreOrders()
      setIsInitialized(true)
    }

    if (isInitialized && !user?.token) {
      clearOrders()
    }
  }, [isInitialized, loadMoreOrders, user?.token])

  return {
    orders,
    hasMoreOrders,
    loadMoreOrders,
    isLoadingOrders,
  }
}

/**
 * User product list hook.
 */
export const useUserProductList = () => {
  const { locale } = useContext(LanguageContext)
  const { productCatalogue } = useContext(ShopContext)
  const { user } = useUser()
  const [products, setProducts] = useState<UserProduct[]>([])
  const [isLoadingProducts, setIsLoadingProducts] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)

  const clearProducts = () => {
    setProducts([])
    setIsInitialized(false)
  }

  // Initialize product list
  useEffect(() => {
    const userToken = user?.token
    const userProductIds = user?.ownedProducts?.map(({ id }) => id)

    if (!isInitialized && userToken && userProductIds) {
      const userSubscriptionProductIds =
        user?.activeSubscriptionProductIds ?? []

      ;(async () => {
        setIsInitialized(true)

        // Get all digital products owned by the user (including products from subscriptions)
        const userDigitalProducts = getUserDigitalProducts(
          productCatalogue,
          userProductIds,
          userSubscriptionProductIds
        )

        setProducts(userDigitalProducts)

        setIsLoadingProducts(false)
      })()
    }

    if (isInitialized && !userToken) {
      clearProducts()
    }
  }, [isInitialized, locale, productCatalogue, user])

  return {
    isLoadingProducts,
    products,
    productsPerPage: USER_LIST_PAGE_LIMIT,
  }
}
