import { parseRequiredParameter } from '@lib/helpers'
import { getLocaleVariable, Locale } from '@lib/language'

type ShopifyHeaderKey = 'Content-Type' | 'X-Shopify-Access-Token'

export type ShopifyHeaders = Record<ShopifyHeaderKey, string>

type RechargeHeaderKey = 'Content-Type' | 'X-Recharge-Access-Token'

export type RechargeHeaders = Record<RechargeHeaderKey, string>

export const SHOPIFY_API_VERSION = '2021-10'

/**
 * Gets Recharge request headers.
 */
export const getRechargeHeaders = (locale: Locale): RechargeHeaders => {
  const rechargeToken = getLocaleVariable(locale, 'RECHARGE_API_TOKEN') ?? ''

  return {
    'Content-Type': 'application/json',
    'X-Recharge-Access-Token': rechargeToken,
  }
}

/**
 * Gets Shopify domain.
 */
export const getShopifyDomain = (locales: Locale[] | Locale): string => {
  const locale = parseRequiredParameter<Locale>(locales)
  const storeId = getLocaleVariable(locale, 'SHOPIFY_STORE_ID')

  return `${storeId}.myshopify.com`
}

/**
 * Gets Shopify Storefront API URL.
 */
export const getShopifyStorefrontApiUrl = (shopifyDomain: string): string =>
  `https://${shopifyDomain}/api/${SHOPIFY_API_VERSION}`

/**
 * Gets Shopify Admin API URL.
 */
export const getShopifyAdminApiUrl = (shopifyDomain: string): string =>
  `https://${shopifyDomain}/admin/api/${SHOPIFY_API_VERSION}`

/**
 * Encodes Shopify global ID with base 64.
 */
export const getShopifyGlobalId = (type: string, id: number) =>
  Buffer.from(`gid://shopify/${type}/${id}`, 'utf-8').toString('base64')

/**
 * Decodes and gets Shopify global object ID.
 */
export const parseShopifyGlobalId = (globalId: string) => {
  try {
    const id = Buffer.from(globalId, 'base64')
      .toString('utf-8')
      ?.split('?')?.[0]
      ?.split('/')
      ?.slice(-1)?.[0]

    if (id) {
      return Number(id)
    }
  } catch (_) {
    return
  }
}

/**
 * Gets Shopify headers with access token.
 */
export const getShopifyHeaders = (
  locales: Locale | Locale[]
): ShopifyHeaders => {
  const locale = parseRequiredParameter<Locale>(locales)
  const apiPassword = getLocaleVariable(locale, 'SHOPIFY_API_PASSWORD')

  return {
    'Content-Type': 'application/json',
    'X-Shopify-Access-Token': apiPassword ?? '',
  }
}
