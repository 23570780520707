import { ButtonHTMLAttributes } from 'react'
import cx from 'classnames'
import { useId } from '@reach/auto-id'

import {
  ButtonVariant,
  ButtonColor,
  ButtonSize,
  SizeClassMap,
  getBaseClasses,
  colorClasses,
} from '@components/buttons/button'
import Icon, { IconName } from '@components/icon'

const sizeClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonSize.SMALL]: 'p-2 text-sm',
  [ButtonSize.NORMAL]: 'p-3 text-lg',
  [ButtonSize.LARGE]: 'p-4 text-xl',
}

interface IconButtonProps {
  icon: IconName
  variant?: ButtonVariant
  size?: ButtonSize
  color?: ButtonColor
}

const IconButton = ({
  icon,
  variant,
  size = ButtonSize.NORMAL,
  color = ButtonColor.DEFAULT,
  className,
  onClick,
  disabled,
  type,
}: IconButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const id = useId()

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={cx(
        getBaseClasses(variant),
        variant ? colorClasses[variant][color] : '',
        sizeClasses[size],
        'drop-shadow-lg',
        className
      )}
    >
      <Icon id={`button-icon-${id}`} name={icon} />
    </button>
  )
}

export default IconButton
