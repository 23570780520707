import cx from 'classnames'

import { SanityGrid, SanityColor } from '@data/sanity/queries/types/modules'
import { getGridSizeClass, getItemOrderClass } from '@lib/dom'
import { borderRadiusClassMap } from '@lib/image'

import GridBlock from './grid-block'

type GridProps = SanityGrid

type ColorClassMap = Record<SanityColor, string | string[]>

// TODO: Move somewhere else
export const backgroundColorClasses: ColorClassMap = {
  [SanityColor.WHITE]: 'bg-white text-black',
  [SanityColor.GRAY]: 'bg-gray text-black',
  [SanityColor.GREEN_LIGHT]: 'bg-green-light text-black',
  [SanityColor.GREEN]: 'bg-green text-white',
  [SanityColor.GREEN_ELECTRIC]: 'bg-green-electric text-white',
  [SanityColor.GREEN_DARK]: 'bg-green-dark text-white',
  [SanityColor.PINK_FADED]: 'bg-pink-faded text-black',
  [SanityColor.PINK]: 'bg-pink text-white text-black',
  [SanityColor.PINK_HOT]: 'bg-pink-hot text-white',
  [SanityColor.BLUE]: 'bg-blue text-white',
  [SanityColor.RED]: 'bg-red text-white',
  [SanityColor.YELLOW]: 'bg-yellow text-black',
}

const getSpacingClasses = (
  spacing: any, // TODO: Add type
  type: 'padding' | 'margin',
  side?: 'top' | 'right' | 'bottom' | 'left'
) => {
  const t = type.charAt(0)
  const s = spacing?.[type]

  const top = {
    [`${t}t-${s?.top}`]: s?.top,
    [`sm:${t}t-${s?.smTop}`]: s?.smTop,
    [`md:${t}t-${s?.mdTop}`]: s?.mdTop,
    [`lg:${t}t-${s?.lgTop}`]: s?.lgTop,
    [`xl:${t}t-${s?.xlTop}`]: s?.xlTop,
  }

  const right = {
    [`${t}r-${s?.right}`]: s?.right,
    [`sm:${t}r-${s?.smRight}`]: s?.smRight,
    [`md:${t}r-${s?.mdRight}`]: s?.mdRight,
    [`lg:${t}r-${s?.lgRight}`]: s?.lgRight,
    [`xl:${t}r-${s?.xlRight}`]: s?.xlRight,
  }

  const bottom = {
    [`${t}b-${s?.bottom}`]: s?.bottom,
    [`sm:${t}b-${s?.smBottom}`]: s?.smBottom,
    [`md:${t}b-${s?.mdBottom}`]: s?.mdBottom,
    [`lg:${t}b-${s?.lgBottom}`]: s?.lgBottom,
    [`xl:${t}b-${s?.xlBottom}`]: s?.xlBottom,
  }

  const left = {
    [`${t}l-${s?.left}`]: s?.left,
    [`sm:${t}l-${s?.smLeft}`]: s?.smLeft,
    [`md:${t}l-${s?.mdLeft}`]: s?.mdLeft,
    [`lg:${t}l-${s?.lgLeft}`]: s?.lgLeft,
    [`xl:${t}l-${s?.xlLeft}`]: s?.xlLeft,
  }

  if (side === 'top') {
    return top
  }

  if (side === 'right') {
    return right
  }

  if (side === 'bottom') {
    return bottom
  }

  if (side === 'left') {
    return left
  }

  return {
    ...top,
    ...right,
    ...bottom,
    ...left,
  }
}

const Grid = ({
  size,
  spacing,
  columns: rawColumns,
  reverseSequence,
  background,
  noColumnGaps,
  noRowGaps,
}: GridProps) => {
  const columns = rawColumns.map((column, columnIndex) => {
    const columnClasses = column.sizes.map(
      ({
        breakpoint,
        width,
        justify,
        align,
        start,
        justifyContent,
        alignContent,
      }) =>
        getGridSizeClass(
          breakpoint,
          width,
          justify,
          align,
          start,
          justifyContent,
          alignContent
        )
    )
    const orderClasses = column.sizes.map(({ breakpoint, width }) =>
      getItemOrderClass(columnIndex, breakpoint, width, size, reverseSequence)
    )

    return {
      ...column,
      classes: [...columnClasses, ...orderClasses],
    }
  })

  return (
    <section
      className={cx(
        `${background ? backgroundColorClasses[background] : ''}`,
        getSpacingClasses(spacing, 'margin')
      )}
    >
      <div
        className={cx(
          'relative container',
          `pt-${spacing?.padding?.top ?? 12} pb-${
            spacing?.padding?.bottom ?? 12
          }`,
          `sm:pt-${spacing?.padding?.smTop ?? 16} sm:pb-${
            spacing?.padding?.smBottom ?? 16
          }`,
          `md:pt-${spacing?.padding?.mdTop ?? 24} md:pb-${
            spacing?.padding?.mdBottom ?? 24
          }`,
          `lg:pt-${spacing?.padding?.lgTop ?? 32} lg:pb-${
            spacing?.padding?.lgBottom ?? 32
          }`,
          getSpacingClasses(spacing, 'padding', 'right'),
          getSpacingClasses(spacing, 'padding', 'left')
        )}
      >
        <div className="mx-auto">
          <div
            className={cx(
              `grid grid-cols-${size} gap-x-4 gap-y-8 sm:gap-x-8 lg:gap-x-10 lg:gap-y-10`,
              {
                '!gap-x-0': noColumnGaps,
                '!gap-y-0': noRowGaps,
              }
            )}
          >
            {columns.map(
              ({
                _key,
                blocks,
                classes,
                spacing,
                background,
                borderRadius,
              }) => (
                <div
                  key={_key}
                  className={cx(
                    classes,
                    `${background ? backgroundColorClasses[background] : ''}`,
                    borderRadius ? borderRadiusClassMap[borderRadius] : '',
                    getSpacingClasses(spacing, 'margin'),
                    getSpacingClasses(spacing, 'padding')
                  )}
                >
                  {blocks.map((block) => (
                    <GridBlock key={block._key} block={block} />
                  ))}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
