import { BaseSyntheticEvent, useContext, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import BlockContent from '@sanity/block-content-to-react'

import { HasAccountStrings } from '@data/sanity/queries/types/site'
import {
  useCreateAddress,
  useUpdateAddress,
} from '@lib/shopify/graphql/customer-address'
import { ParseStatus } from '@lib/shopify/graphql/client'
import { useUser } from '@lib/auth'
import { CountryCode, countryNames } from '@lib/country'
import { ErrorMessages } from '@lib/helpers'
import { LanguageContext, Locale } from '@lib/language'
import { serializers } from '@lib/serializers'
import { StringsContext } from '@lib/strings'
import { AddressFormValues } from '@lib/user'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import InputDropdown, { DropdownOption } from '@components/input-dropdown'
import InputField from '@components/input-field'

export enum AddressFormMode {
  CREATE = 'create',
  EDIT = 'edit',
}

interface AddressFormProps extends HasAccountStrings {
  mode: AddressFormMode
  hide: () => void
  addressId?: string
  defaultValues?: AddressFormValues
  className?: string
}

const AddressForm = ({
  accountStrings,
  mode,
  hide,
  addressId,
  defaultValues,
  className,
}: AddressFormProps) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<AddressFormValues>()
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)
  const createAddress = useCreateAddress()
  const updateAddress = useUpdateAddress()
  const { user } = useUser()

  // Country list
  const countryOptions = useMemo<DropdownOption[]>(
    () =>
      Object.entries(countryNames[locale])
        .map(([code, name]) => ({ code: code as CountryCode, name }))
        .map(({ code, name }) => ({
          value: countryNames[Locale.ENGLISH][code],
          title: name,
        })),
    [locale]
  )

  // Handle form submission
  const onSubmit = async (
    values: AddressFormValues,
    event?: BaseSyntheticEvent
  ) => {
    event?.preventDefault()

    const token = user?.token

    if (!token) {
      setIsError(true)
      return
    }

    setIsLoading(true)
    setIsError(false)

    if (mode === AddressFormMode.CREATE) {
      // Create address
      const createAddressResult = await createAddress(values, token)
      setErrorMessages(createAddressResult.fieldErrors)

      if (createAddressResult.status !== ParseStatus.OK) {
        setIsError(true)
      }

      if (
        createAddressResult.status === ParseStatus.OK &&
        createAddressResult.errorCount === 0
      ) {
        reset()
        hide()
      }
    }

    if (mode === AddressFormMode.EDIT && addressId) {
      // Update address
      const updateAddressResult = await updateAddress(addressId, values, token)
      setErrorMessages(updateAddressResult.fieldErrors)

      if (updateAddressResult.status !== ParseStatus.OK) {
        setIsError(true)
      }

      if (
        updateAddressResult.status === ParseStatus.OK &&
        updateAddressResult.errorCount === 0
      ) {
        reset()
        hide()
      }
    }

    setIsLoading(false)
  }

  const firstNameRegister = register('firstName', {
    required: accountStrings.accountFirstNameMissing,
  })
  const lastNameRegister = register('lastName', {
    required: accountStrings.accountLastNameMissing,
  })
  const companyRegister = register('company', {
    required: accountStrings.accountCompanyMissing,
  })
  const address1Register = register('address1', {
    required: accountStrings.accountAddressLine1Missing,
  })
  const address2Register = register('address2')
  const cityRegister = register('city', {
    required: accountStrings.accountCityMissing,
  })
  const countryRegister = register('country', {
    required: accountStrings.accountCountryMissing,
  })
  const zipRegister = register('zip', {
    required: accountStrings.accountZipMissing,
  })
  const phoneRegister = register('phone', {
    required: accountStrings.accountPhoneMissing,
  })
  const isDefaultRegister = register('isDefault')

  const isDisabled =
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.company ||
    !!errors.address1 ||
    !!errors.city ||
    !!errors.country ||
    !!errors.zip ||
    !!errors.phone

  return (
    <div className={className}>
      <h4>
        {mode === 'create'
          ? accountStrings.accountAddAddressHeading
          : accountStrings.accountEditAddressHeading}
      </h4>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
        <div className="grid grid-cols-6 gap-x-8 gap-y-5">
          <div className="col-span-6 sm:col-span-3">
            <InputField
              id="address-fist-name"
              type="text"
              formRegister={firstNameRegister}
              defaultValue={defaultValues?.firstName}
              errorMessage={
                errorMessages?.firstName ?? errors.firstName?.message
              }
              label={accountStrings.accountFirstName}
              placeholder={accountStrings.accountFirstNamePlaceholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              id="address-last-name"
              type="text"
              formRegister={lastNameRegister}
              defaultValue={defaultValues?.lastName}
              errorMessage={errorMessages?.lastName ?? errors.lastName?.message}
              label={accountStrings.accountLastName}
              placeholder={accountStrings.accountLastNamePlaceholder}
            />
          </div>

          <div className="col-span-6">
            <InputField
              id="address-company"
              type="text"
              formRegister={companyRegister}
              defaultValue={defaultValues?.company}
              errorMessage={errorMessages?.company ?? errors.company?.message}
              label={accountStrings.accountCompany}
              placeholder={accountStrings.accountCompanyPlaceholder}
            />
          </div>

          <div className="col-span-6">
            <InputField
              id="address-address-line-1"
              type="text"
              formRegister={address1Register}
              defaultValue={defaultValues?.address1}
              errorMessage={errorMessages?.address1 ?? errors.address1?.message}
              label={accountStrings.accountAddressLine1}
              placeholder={accountStrings.accountAddressLine1Placeholder}
            />
          </div>

          <div className="col-span-6">
            <InputField
              id="address-address-line-2"
              type="text"
              formRegister={address2Register}
              defaultValue={defaultValues?.address2}
              errorMessage={errorMessages?.address2 ?? errors.address2?.message}
              label={accountStrings.accountAddressLine2}
              placeholder={accountStrings.accountAddressLine2Placeholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              id="address-city"
              type="text"
              formRegister={cityRegister}
              defaultValue={defaultValues?.city}
              errorMessage={errorMessages?.city ?? errors.city?.message}
              label={accountStrings.accountCity}
              placeholder={accountStrings.accountCityPlaceholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputDropdown
              id="address-country"
              formRegister={countryRegister}
              defaultValue={defaultValues?.country}
              options={countryOptions}
              errorMessage={errorMessages?.country ?? errors.country?.message}
              label={accountStrings.accountCountry}
              placeholder={accountStrings.accountCountryPlaceholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              id="address-zip"
              type="text"
              formRegister={zipRegister}
              defaultValue={defaultValues?.zip}
              errorMessage={errorMessages?.zip ?? errors.zip?.message}
              label={accountStrings.accountZip}
              placeholder={accountStrings.accountZipPlaceholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              id="address-phone"
              type="text"
              formRegister={phoneRegister}
              defaultValue={defaultValues?.phone}
              errorMessage={errorMessages?.phone ?? errors.phone?.message}
              label={accountStrings.accountPhone}
              placeholder={accountStrings.accountPhonePlaceholder}
            />
          </div>

          <div className="col-span-6">
            <Checkbox
              id="address-is-default"
              formRegister={isDefaultRegister}
              defaultChecked={defaultValues?.isDefault}
              className="text-sm"
            >
              {accountStrings.accountSetAsDefault}
            </Checkbox>
          </div>

          {isError && (
            <div key="error" className="col-span-6 my-4">
              <Alert error>
                <BlockContent
                  renderContainerOnSingleChild
                  className="rc rc-alert rc-error"
                  blocks={accountStrings.addressErrorMessage}
                  serializers={serializers}
                />
              </Alert>
            </div>
          )}

          <div className="col-span-6 mt-4">
            <Button
              type="submit"
              variant={ButtonVariant.FILLED}
              disabled={isLoading || isDisabled}
              className="min-w-[180px] xs:mr-7 mb-4 w-full xs:w-auto"
            >
              {isLoading
                ? strings.buttonSubmitting
                : mode === 'create'
                ? accountStrings.accountAddAddress
                : accountStrings.accountEditAddress}
            </Button>

            <Button
              variant={ButtonVariant.OUTLINED}
              onClick={(event) => {
                event.preventDefault()
                hide()
              }}
              className="min-w-[180px] w-full xs:w-auto"
            >
              {strings.buttonCancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddressForm
