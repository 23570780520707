import { gql } from '@apollo/client'

export const SHOP_FRAGMENT = gql`
  fragment ShopFragment on Shop {
    name
    currencyCode
    moneyFormat
    primaryDomain {
      host
    }
    paymentSettings {
      countryCode
    }
  }
`

export const MAILING_ADDRESS_FRAGMENT = gql`
  fragment MailingAddressFragment on MailingAddress {
    id
    formatted(withName: true)
    firstName
    lastName
    company
    address1
    address2
    city
    country
    zip
    phone
  }
`

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    name
    processedAt
    financialStatus
    fulfillmentStatus
    totalPriceV2 {
      amount
    }
    statusUrl
  }
`

export const ORDER_PRODUCT_FRAGMENT = gql`
  fragment OrderProductFragment on Order {
    id
    lineItems(first: 25) {
      edges {
        node {
          variant {
            id
            product {
              id
              handle
              title
            }
          }
        }
      }
    }
  }
`

export const CART_FRAGMENT = gql`
  fragment CartFragment on Cart {
    id
    checkoutUrl
    lines(first: 25) {
      edges {
        node {
          id
          quantity
          merchandise {
            ... on ProductVariant {
              id
            }
          }
          discountAllocations {
            ... on CartAutomaticDiscountAllocation {
              title
              discountedAmount {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
    discountCodes {
      applicable
      code
    }
    estimatedCost {
      subtotalAmount {
        amount
        currencyCode
      }
    }
  }
`
