import { ReactNode, useEffect, useRef, useState } from 'react'
import { m } from 'framer-motion'
import cx from 'classnames'

import { SanityTeamMember } from '@data/sanity/queries/types/blocks'
import { PageType, getPageUrl } from '@lib/routes'

import Photo from '@components/photo'
import SimpleLink from '@components/simple-link'
import Icon from '@components/icon'

interface TeamMemberCardProps {
  teamMember: SanityTeamMember
}

const TeamMemberCard = ({
  teamMember: { name, slug, jobTitle, about, photo, linkedInUrl, blogAuthor },
}: TeamMemberCardProps) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (!isModalOpen) {
      return
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setIsModalOpen(false)
      }
    }

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [isModalOpen])

  const blogAuthorPageUrl = blogAuthor
    ? getPageUrl(PageType.BLOG_AUTHOR, blogAuthor.slug)
    : null

  return (
    <div className="flex flex-col items-center relative">
      {photo ? (
        <Photo
          image={photo}
          objectFit="cover"
          forceWidth={200}
          forceHeight={200}
          className="rounded-full overflow-hidden"
        />
      ) : (
        <PlaceholderImage />
      )}

      <h5 className="text-2xl mt-3">{name}</h5>
      {jobTitle && <p className="text-base">{jobTitle}</p>}

      <m.div
        ref={modalRef}
        initial="hide"
        animate={isModalOpen ? 'show' : 'hide'}
        variants={{
          show: {
            opacity: 1,
            y: '0%',
          },
          hide: {
            opacity: 0,
            y: '2%',
          },
        }}
        transition={{ duration: 0.3, ease: [0.15, 1, 0.5, 1] }}
        className="flex flex-col items-center py-5 px-2 mb-9 absolute inset-0 bg-backdrop bg-opacity-90 rounded-md"
      >
        <div className="overflow-y-scroll white-scrollbar text-white w-full">
          <div className="w-full flex flex-col items-start gap-y-2 mb-5 px-2">
            {linkedInUrl && (
              <Link
                id={`team-member-${slug}-card-modal-linkedin-link`}
                href={linkedInUrl}
                openInNewTab
                hasArrowIcon
              >
                Connect on LinkedIn
              </Link>
            )}

            {blogAuthorPageUrl && (
              <Link
                id={`team-member-${slug}-card-modal-blog-link`}
                href={blogAuthorPageUrl}
                hasArrowIcon
              >
                Read blog articles
              </Link>
            )}
          </div>

          <p className="w-full px-2 text-base">{about}</p>
        </div>
      </m.div>

      <hr className="my-3 bg-current w-[40px]" />

      <div className="flex nowrap items-center gap-x-3">
        {linkedInUrl && (
          <Link
            id={`team-member-${slug}-card-linkedin-link`}
            href={linkedInUrl}
            openInNewTab
          >
            LinkedIn
          </Link>
        )}

        {blogAuthorPageUrl && (
          <Link
            id={`team-member-${slug}-card-blog-link`}
            href={blogAuthorPageUrl}
          >
            Blog
          </Link>
        )}

        {about && (
          <button
            className="border rounded-full p-[0.2rem]"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            <Icon
              name="Plus"
              id={`team-member-card-${slug}-more`}
              className={cx('translate-y-[0.5px] transition-transform', {
                'rotate-45': isModalOpen,
              })}
            />
          </button>
        )}
      </div>
    </div>
  )
}

interface LinkProps {
  children: ReactNode
  id: string
  href: string
  openInNewTab?: boolean
  hasArrowIcon?: boolean
}

const Link = ({
  children,
  id,
  href,
  openInNewTab,
  hasArrowIcon,
}: LinkProps) => {
  const arrowIcon = hasArrowIcon ? (
    <Icon id={`icon-${id}`} name="ArrowRight" className="text-xl" />
  ) : null

  const classes = cx({
    'inline-flex items-center gap-x-2': arrowIcon,
  })

  if (openInNewTab) {
    return (
      <a href={href} target="_blank" rel="noreferrer" className={classes}>
        {arrowIcon}
        {children}
      </a>
    )
  }

  return (
    <SimpleLink href={href} className={classes}>
      {arrowIcon}
      {children}
    </SimpleLink>
  )
}

const PlaceholderImage = () => null

export default TeamMemberCard
