import { colors } from '@lib/theme'
import { useMemo } from 'react'

import { InlineWidget } from 'react-calendly'

interface CalendlyWidgetProps {
  url: string
  hideEventDetails?: boolean
  hideCookieSettings?: boolean
}

const CalendlyWidget = ({
  url,
  hideEventDetails,
  hideCookieSettings = true,
}: CalendlyWidgetProps) => {
  const params = useMemo(() => {
    const searchParams = new URLSearchParams({
      background_color: colors.pageBG.replace('#', ''),
      text_color: colors.pageText.replace('#', ''),
      primary_color: colors.btn.filled.default.bg.DEFAULT.replace('#', ''),
    })

    if (hideEventDetails) {
      searchParams.set('hide_event_type_details', '1')
    }

    if (hideCookieSettings) {
      searchParams.set('hide_gdpr_banner', '1')
    }

    return searchParams.toString()
  }, [hideEventDetails, hideCookieSettings])

  return <InlineWidget url={`${url}?${params}`} />
}

export default CalendlyWidget
