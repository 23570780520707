import { useEffect } from 'react'
import cx from 'classnames'

import {
  SanityAccordionsBlock,
  SanityAccountAddressDetailsBlock,
  SanityAccountDetailsBlock,
  SanityAccountOrderListBlock,
  SanityAccountProductListBlock,
  SanityBlocks,
  SanityBlockType,
  SanityBlogPostCardBlock,
  SanityContentCarouselBlock,
  SanityCollectionCarouselBlock,
  SanityFreeformBlock,
  SanityLoginFormBlock,
  SanityNewsletterBlock,
  SanityPasswordRecoveryFormBlock,
  SanityProductBundleFormBlock,
  SanityProductCardBlock,
  SanitySignupFormBlock,
  SanityVideoBlock,
  SanityCalendlyWidgetBlock,
  SanityCookieDeclarationBlock,
  SanityDemoFormBlock,
  SanityIframeBlock,
  SanityTeamMemberCardBlock,
  SanityHtmlBlock,
} from '@data/sanity/queries/types/blocks'

import Accordions from '@blocks/accordions'
import BlogPostCard from '@blocks/blog/blog-post-card'
import Freeform from '@blocks/freeform'
import Newsletter from '@blocks/newsletter'
import ContentCarousel from '@blocks/content-carousel'
import CollectionCarousel from '@blocks/shop/collection-carousel'
import ProductBundleForm from '@blocks/shop/product-bundle-form'
import ProductCard from '@blocks/shop/product-card'
import CalendlyWidget from '@blocks/calendly-widget'
import AccountAddressDetails from './account/account-address-details'
import AccountDetails from './account/account-details'
import AccountOrderList from './account/account-order-list'
import AccountProductList from './account/account-product-list'
import LoginForm from './account/login-form'
import PasswordRecoveryForm from './account/password-recovery-form'
import SignupForm from './account/signup-form'
import VideoModule from './video'
import CookieDeclaration from '@blocks/cookiebot/cookie-declaration'
import DemoForm from '@blocks/demo-form'
import TeamMemberCard from '@blocks/team-member-card'

interface GridBlockProps {
  block: SanityBlocks
}

const GridBlock = ({ block }: GridBlockProps) => {
  useEffect(() => {
    // A quick fix for executing scripts from embedded HTML
    if (block._type === SanityBlockType.HTML) {
      const { code } = block as SanityHtmlBlock

      if (code) {
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = code
        const scripts = tempDiv.querySelectorAll('script')
        scripts.forEach((script) => {
          const newScript = document.createElement('script')
          newScript.textContent = script.textContent
          document.body.appendChild(newScript)
        })
      }
    }
  }, [block])

  switch (block._type as SanityBlockType) {
    case SanityBlockType.FREEFORM: {
      const freeformBlock = block as SanityFreeformBlock

      return (
        <Freeform
          content={freeformBlock.content}
          textAlign={freeformBlock.textAlign}
          maxWidth={freeformBlock.maxWidth}
        />
      )
    }

    case SanityBlockType.ACCORDIONS: {
      const accordionsBlock = block as SanityAccordionsBlock

      return <Accordions items={accordionsBlock.items} />
    }

    case SanityBlockType.PRODUCT_CARD: {
      const productCardBlock = block as SanityProductCardBlock

      return (
        <ProductCard
          product={productCardBlock.product}
          hasVisuals
          showThumbnails
          showOption
          showPrice
          isInline
        />
      )
    }

    case SanityBlockType.SIGNUP_FORM: {
      const signupFormBlock = block as SanitySignupFormBlock

      if (!signupFormBlock.active) {
        return null
      }

      return (
        <SignupForm
          authStrings={signupFormBlock.authStrings}
          className="mt-16 mb-10"
        />
      )
    }

    case SanityBlockType.LOGIN_FORM: {
      const loginFormBlock = block as SanityLoginFormBlock

      if (!loginFormBlock.active) {
        return null
      }

      return (
        <LoginForm
          authStrings={loginFormBlock.authStrings}
          className="mt-16 mb-10"
        />
      )
    }

    case SanityBlockType.PASSWORD_RECOVERY_FORM: {
      const passwordRecoveryFormBlock = block as SanityPasswordRecoveryFormBlock

      if (!passwordRecoveryFormBlock.active) {
        return null
      }

      return (
        <PasswordRecoveryForm
          authStrings={passwordRecoveryFormBlock.authStrings}
          className="mt-16 mb-10"
        />
      )
    }

    case SanityBlockType.ACCOUNT_DETAILS: {
      const accountDetailsBlock = block as SanityAccountDetailsBlock

      if (!accountDetailsBlock.active) {
        return null
      }

      return (
        <AccountDetails
          accountStrings={accountDetailsBlock.accountStrings}
          className="mb-10"
        />
      )
    }

    case SanityBlockType.ACCOUNT_ADDRESS_DETAILS: {
      const accountAddressDetailsBlock =
        block as SanityAccountAddressDetailsBlock

      if (!accountAddressDetailsBlock.active) {
        return null
      }

      return (
        <AccountAddressDetails
          accountStrings={accountAddressDetailsBlock.accountStrings}
          className="mt-10 mb-10"
        />
      )
    }

    case SanityBlockType.ACCOUNT_PRODUCT_LIST: {
      const accountProductListBlock = block as SanityAccountProductListBlock

      if (!accountProductListBlock.active) {
        return null
      }

      return (
        <AccountProductList
          accountStrings={accountProductListBlock.accountStrings}
          className="mb-16"
        />
      )
    }

    case SanityBlockType.ACCOUNT_ORDER_LIST: {
      const accountOrderListBlock = block as SanityAccountOrderListBlock

      if (!accountOrderListBlock.active) {
        return null
      }

      return (
        <AccountOrderList
          accountStrings={accountOrderListBlock.accountStrings}
          className="mb-16"
        />
      )
    }

    case SanityBlockType.VIDEO: {
      const videoBlock = block as SanityVideoBlock

      return (
        <VideoModule
          type={videoBlock.type}
          vimeoVideo={videoBlock.vimeoVideo}
          muxVideo={videoBlock.muxVideo}
          settings={videoBlock.settings}
          aspectRatio={videoBlock.aspectRatio}
          borderRadius={videoBlock.borderRadius}
        />
      )
    }

    case SanityBlockType.COLLECTION_CAROUSEL: {
      const collectionCarouselBlock = block as SanityCollectionCarouselBlock

      return (
        <CollectionCarousel collection={collectionCarouselBlock.collection} />
      )
    }

    case SanityBlockType.NEWSLETTER: {
      const newsletterBlock = block as SanityNewsletterBlock

      return (
        <Newsletter
          id={newsletterBlock._key}
          title={newsletterBlock.title}
          description={newsletterBlock.description}
          variant={newsletterBlock.variant}
          service={newsletterBlock.service}
          hubSpotFormId={newsletterBlock.hubSpotFormId}
          klaviyoListID={newsletterBlock.klaviyoListID}
          submit={newsletterBlock.submit}
          successMsg={newsletterBlock.successMsg}
          errorMsg={newsletterBlock.errorMsg}
          terms={newsletterBlock.terms}
        />
      )
    }

    case SanityBlockType.PRODUCT_BUNDLE_FORM: {
      const productBundleFormBlock = block as SanityProductBundleFormBlock

      return (
        <ProductBundleForm
          productBundle={productBundleFormBlock.productBundle}
          showGallery={productBundleFormBlock.showGallery}
        />
      )
    }

    case SanityBlockType.BLOG_POST_CARD: {
      const blogPostCardBlock = block as SanityBlogPostCardBlock

      const { post, options } = blogPostCardBlock

      return <BlogPostCard post={post} options={options} />
    }

    case SanityBlockType.CONTENT_CAROUSEL: {
      const contentCarouselBlock = block as SanityContentCarouselBlock

      return <ContentCarousel items={contentCarouselBlock.items} />
    }

    case SanityBlockType.CALENDLY_WIDGET: {
      const calendlyWidgetBlock = block as SanityCalendlyWidgetBlock

      const { url, hideEventDetails, hideCookieSettings } = calendlyWidgetBlock

      return (
        <CalendlyWidget
          url={url}
          hideEventDetails={hideEventDetails}
          hideCookieSettings={hideCookieSettings}
        />
      )
    }

    case SanityBlockType.COOKIE_DECLARATION: {
      const cookieDeclarationBlock = block as SanityCookieDeclarationBlock

      if (
        !cookieDeclarationBlock.active ||
        !cookieDeclarationBlock.cookieBotId
      ) {
        return null
      }

      return (
        <CookieDeclaration cookieBotId={cookieDeclarationBlock.cookieBotId} />
      )
    }

    case SanityBlockType.DEMO_FORM: {
      const {
        _key,
        service,
        hubSpotFormId,
        hubSpotNewsletterFormId,
        klaviyoListID,
        klaviyoNewsletterListID,
        submit,
        successMsg,
        errorMsg,
        terms,
        newsletterStatement,
        buttonStyle,
        strings,
      } = block as SanityDemoFormBlock

      return (
        <DemoForm
          id={_key}
          service={service}
          hubSpotFormId={hubSpotFormId}
          hubSpotNewsletterFormId={hubSpotNewsletterFormId}
          klaviyoListID={klaviyoListID}
          klaviyoNewsletterListID={klaviyoNewsletterListID}
          submit={submit}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
          newsletterStatement={newsletterStatement}
          buttonStyle={buttonStyle}
          strings={strings}
        />
      )
    }

    case SanityBlockType.IFRAME: {
      const iframeBlock = block as SanityIframeBlock

      const { title, url, width, height } = iframeBlock

      return (
        <iframe
          title={title}
          src={url}
          className={cx({ 'w-full': !width })}
          width={width}
          height={height}
        />
      )
    }

    case SanityBlockType.TEAM_MEMBER_CARD: {
      const teamMemberCardBlock = block as SanityTeamMemberCardBlock

      const { teamMember } = teamMemberCardBlock

      return <TeamMemberCard teamMember={teamMember} />
    }

    case SanityBlockType.HTML: {
      const htmlBlock = block as SanityHtmlBlock

      const { code } = htmlBlock

      return code ? <div dangerouslySetInnerHTML={{ __html: code }} /> : null
    }
  }
}

export default GridBlock
