const range = (start, end) =>
  Array(end)
    .fill()
    .map((_x, i) => start + i)

const generateClasses = (property, values, responsive = true, negative) => {
  const classes = []

  for (const value of values) {
    const c = `${property}-${value}`
    classes.push(c)

    if (negative) classes.push(`-${c}`)

    if (responsive) {
      for (const screen of ['xs', 'sm', 'md', 'lg', 'xl']) {
        classes.push(`${screen}:${c}`)
        if (negative) classes.push(`-${screen}:${c}`)
      }
    }
  }

  return classes
}

const colorSafelist = [
  'gray',
  'pink-faded',
  'pink',
  'pink-hot',
  'green-light',
  'green',
  'green-dark',
  'green-electric',
  'blue',
  'red',
  'yellow',
]

const spacingSafelist = [
  0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 16, 20, 24, 32, 40, 48, 56, 64,
]

const safelist = [
  ...generateClasses('text', ['left', 'center', 'right', 'justify']),
  ...generateClasses('animate', ['none', 'spin', 'ping', 'pulse', 'bounce']),
  ...generateClasses('grid-cols', [...range(1, 12), 'none']),
  ...generateClasses('col-span', [...range(1, 12), 'full']),
  ...generateClasses('col-start', [...range(1, 13), 'auto']),
  ...generateClasses(
    'order',
    [...range(0, 37), 'first', 'last', 'none'],
    true,
    true
  ),
  ...generateClasses('self', ['auto', 'start', 'end', 'center', 'stretch']),
  ...generateClasses('justify-self', [
    'auto',
    'start',
    'end',
    'center',
    'stretch',
  ]),
  ...generateClasses('max-w', [
    '0',
    'none',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7x',
    'full',
    'min',
    'max',
    'prose',
    'screen-sm',
    'screen-md',
    'screen-lg',
    'screen-xl',
    'screen-2xl',
  ]),
  ...generateClasses('min-h', ['screen', 'xs', 'sm', 'md', 'lg', 'xl']),
  ...generateClasses('pt', [
    '[100%]',
    '[calc((3/4)*100%)]',
    '[calc((7/5)*100%)]',
    '[calc((6/4)*100%)]',
    '[calc((9/16)*100%)]',
    '[calc((7/16)*100%)]',
  ]),
  ...generateClasses('pt', spacingSafelist, true),
  ...generateClasses('pb', spacingSafelist, true),
  ...generateClasses('pr', spacingSafelist, true),
  ...generateClasses('pl', spacingSafelist, true),
  ...generateClasses('mt', spacingSafelist, true),
  ...generateClasses('mb', spacingSafelist, true),
  ...generateClasses('mr', spacingSafelist, true),
  ...generateClasses('ml', spacingSafelist, true),
  ...generateClasses('text', colorSafelist),
  ...generateClasses('bg', colorSafelist, true),
  ...generateClasses('before:bg', colorSafelist, true),
  ...generateClasses('after:bg', colorSafelist, true),
]

const PIXELS_PER_REM = 16

const screens = {
  xs: '480px',
  sm: '768px',
  md: '940px',
  lg: '1200px',
  xl: '1600px',
}

// Font sizes in rem units
const fontSizes = {
  xs: 0.75,
  sm: 0.875,
  base: 1,
  lg: 1.125,
  xl: 1.25,
  '2xl': 1.5,
  '3xl': 1.875,
  '4xl': 2.25,
  '5xl': 3,
  '6xl': 3.75,
  '7xl': 4.5,
  '8xl': 6,
  '9xl': 8,
}

const clampBuilder = (minFontSize, maxFontSize) => {
  const minWidthPx = screens.xs.replace('px', '')
  const maxWidthPx = screens.md.replace('px', '')

  const minWidth = minWidthPx / PIXELS_PER_REM
  const maxWidth = maxWidthPx / PIXELS_PER_REM

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth)
  const yAxisIntersection = -minWidth * slope + minFontSize

  return `clamp(${minFontSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxFontSize}rem )`
}

const getColors = () => {
  const colors = {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000000',
    white: '#FFFFFF',
    gray: '#F0F0F0',
    pink: {
      faded: '#DAB0D2',
      DEFAULT: '#E9AFF1',
      hot: '#E6379B',
    },
    green: {
      light: '#B8F483',
      DEFAULT: '#56BC7A', // Where did this appear from? It's not in the brandbook.
      dark: '#01424F',
      electric: '#18DC82',
    },
    blue: '#2D46AF',
    yellow: '#EFDC2C',
    red: '#F73424',
  }

  return {
    ...colors,
    pageBG: colors.white,
    pageText: colors.black,
    backdrop: colors.black,
    header: {
      bg: colors.white,
      text: colors.blue,
    },
    btn: {
      filled: {
        default: {
          text: {
            DEFAULT: colors.black,
            hover: colors.black,
          },
          bg: {
            DEFAULT: colors.green.light,
            hover: colors.green.light,
          },
          border: {
            DEFAULT: colors.green.light,
            hover: colors.green.light,
          },
        },
      },
      outlined: {
        default: {
          text: {
            DEFAULT: colors.green.light,
            hover: colors.black,
          },
          bg: {
            DEFAULT: colors.transparent,
            hover: colors.green.light,
          },
          border: {
            DEFAULT: colors.green.light,
            hover: colors.green.light,
          },
        },
      },
    },
    input: {
      border: colors.pageText,
      text: colors.pageText,
      bg: colors.transparent,
      inverted: {
        border: colors.pageBG,
        text: colors.pageBG,
        bg: colors.transparent,
      },
    },
    success: {
      DEFAULT: colors.green.DEFAULT,
      bg: '#ECFFEC',
    },
    error: {
      DEFAULT: '#D84949',
      bg: '#FFF0F0',
    },
  }
}

module.exports = {
  safelist,
  screens,
  clampBuilder,
  fontSizes,
  colors: getColors(),
}
